import { Strain } from "@/interfaces/strain";
import { StrainState } from "@/vuex/modules/inventory/strain/strain.types";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";

type StrainMutationTree = MutationTree<StrainState>;

export const mutations: StrainMutationTree = {
  setStrains(state: StrainState, strains: Strain[]) {
    state.strains = strains;
  },
  setLoading(state: StrainState, loading: boolean) {
    state.loading = loading;
  },
  setPagination(state: StrainState, pagination: TablePagination | null) {
    state.pagination = pagination;
  }
};
