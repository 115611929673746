import { Strain } from "@/interfaces/strain";
import { StrainState } from "@/vuex/modules/inventory/strain/strain.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type StrainGetter = GetterTree<StrainState, RootState>;

export const getters: StrainGetter = {
  strains(state: StrainState): Strain[] {
    return state.strains;
  },
  pagination(state: StrainState): TablePagination | null {
    return state.pagination;
  },
  loading(state: StrainState): boolean {
    return state.loading;
  }
};
