import { Strain } from "@/interfaces/strain";
import { messagesService } from "@/services/messages.service";
import { strainService } from "@/services/strain.service";
import { StrainState } from "@/vuex/modules/inventory/strain/strain.types";
import { RootState } from "@/vuex/types";
import { AxiosResponse } from "axios";
import { TablePagination } from "helix-vue-components";
import Vue from "vue";
import { ActionContext, ActionTree } from "vuex";
// tslint:disable-next-line
const toFormData = require("object-to-formdata");

type StrainActionContext = ActionContext<StrainState, RootState>;
type StrainActionTree = ActionTree<StrainState, RootState>;

export const actions: StrainActionTree = {
  /**
   * @param context
   * @param noQuery GET without query params
   */
  async loadStrains(context: StrainActionContext): Promise<any> {
    try {
      context.commit("setLoading", true);
      const payload: Strain[] = await strainService.get();
      const pagination: TablePagination = await strainService.getPagination();
      context.commit("setPagination", pagination);
      context.commit(
        "setStrains",
        payload.map((s: Strain) => ({ ...s, rank: s.rank || "--" }))
      );
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async enableMultiple(
    context: StrainActionContext,
    strains: Strain[]
  ): Promise<any> {
    const strainIds = strains.map((strain: Strain) => strain.id!);
    context.commit("setLoading", true);
    try {
      await strainService.enableMultiple(strainIds);
      messagesService.renderSuccessMessage("updated_successfully");
      context.dispatch("loadStrains");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async disableMultiple(
    context: StrainActionContext,
    strains: Strain[]
  ): Promise<any> {
    const strainIds = strains.map((strain: Strain) => strain.id!);
    context.commit("setLoading", true);
    try {
      await strainService.disableMultiple(strainIds);
      messagesService.renderSuccessMessage("updated_successfully");
      context.dispatch("loadStrains");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async addStrain(
    context: StrainActionContext,
    data: StrainData
  ): Promise<any> {
    try {
      const strain: Strain = data.strain;
      const file: File = data.file;
      strain.notes && strain.notes.length === 0
        ? delete strain.notes
        : (strain.notes = strain.notes!.map(note => {
            const noteFormated = {
              id: note.id,
              name: note.name,
              note: note.note,
              shows_note_at_point_of_sale: note.shows_note_at_point_of_sale,
              // @ts-ignore
              _destroy: note._destroy
            };
            return noteFormated;
          }));
      const formData = toFormData(strain, { indices: true });
      if (file) {
        formData.append("avatar", file, file.name);
      }
      const response = await strainService.post(formData);
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  },

  async updateStrain(
    context: StrainActionContext,
    data: StrainData
  ): Promise<any> {
    try {
      const strain: Strain = data.strain;
      const file: File = data.file;
      !strain.notes || (strain.notes && strain.notes.length === 0)
        ? delete strain.notes
        : (strain.notes = strain.notes!.map(note => {
            const noteFormated = {
              id: note.id,
              name: note.name,
              note: note.note,
              shows_note_at_point_of_sale: note.shows_note_at_point_of_sale,
              // @ts-ignore
              _destroy: note._destroy
            };
            return noteFormated;
          }));
      const formData = toFormData(strain, { indices: true });
      formData.append("_method", "put");
      if (typeof file === "object") {
        formData.append("avatar", file, file.name);
      } else {
        formData.append("avatar", file);
      }

      const response: AxiosResponse = await Vue.axios({
        method: "POST",
        url: `/catalog/strains/${strain.id}`,
        data: formData
      });

      return response.data || response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }
};

interface StrainData {
  file: File;
  strain: Strain;
}
