import { StrainState } from "@/vuex/modules/inventory/strain/strain.types";
import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./strain.actions";
import { getters } from "./strain.getters";
import { mutations } from "./strain.mutations";
import { state } from "./strain.state";

const namespaced: boolean = true;
export const StrainModule: Module<StrainState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
