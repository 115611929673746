import { StrainModule } from "@/vuex/modules/inventory/strain/strain.index";
import { Component, Vue } from "vue-property-decorator";

@Component({
  render: c =>
    c("router-view", {
      style: { height: "100%" }
    })
})
export default class StrainComponent extends Vue {
  protected created() {
    this.$store.registerModule("StrainModule", StrainModule);
  }

  protected beforeDestroy() {
    this.$store.unregisterModule("StrainModule");
  }
}
